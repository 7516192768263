.globalLoader{
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100px;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

.globalLoaderWrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 999999;
}