@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?vr7ca2');
  src:  url('fonts/icomoon.eot?vr7ca2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?vr7ca2') format('truetype'),
    url('fonts/icomoon.woff?vr7ca2') format('woff'),
    url('fonts/icomoon.svg?vr7ca2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e903";
  color: #00458a;
}
.icon-location:before {
  content: "\e902";
}
.icon-train:before {
  content: "\e901";
}
.icon-crowd:before {
  content: "\e900";
  color: #cdcdcd;
}
