@import "./root.css";
@import "./font-face.css";

.MuiInput-underline:before {
  border-bottom-color: #282828 !important;
}

/*colors*/
.darkBlue {
  color: var(--dark-blue);
}
.darkCyan {
  color: var(--dark-cyan);
}
.gray {
  color: var(--gray);
}
.blue {
  color: var(--blue);
}

.lightBlue {
  color: var(--light-blue);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
  margin-top: 50px;
  margin-bottom: 50px;
}

::-webkit-scrollbar-thumb {
  background: var(--pastel-gray);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--light-gray);
}

/* elements -------------------------------------------------------------------------------------------- */
html,
body {
  direction: rtl;
}
html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  writing-mode: inherit;
  letter-spacing: 0.03em;
}

button,
/* [role="button"], */
[type="button"] {
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  justify-content: center;
}
input[type="submit"] {
  cursor: pointer;
}
button:disabled,
[role="button"]:disabled,
[type="button"]:disabled {
  cursor: default;
  opacity: 0.5;
}

button:focus {
  outline: 1px solid black;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-family: "FbReforma-Medium";
  font-size: 39px;
  color: rgba(0, 69, 138, 1);
  text-align: right;
  letter-spacing: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* selectors -------------------------------------------------------------------------------*/
#root {
  /* height: 100%; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.customScrollbar {
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}
.customScrollbar::-webkit-scrollbar {
  width: 5px;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--gray);
}

input[type="checkbox"] {
  position: relative;
  width: 17px;
  height: 17px;
  top: 3px;
}

input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  left: 0;
}

input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  left: 0;
  background-color: rgba(216, 242, 252, 1);
  border: 1px solid rgba(0, 69, 138, 1);
  border-radius: 2px;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid rgba(0, 69, 138, 1);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.sr-only {
  /*screen reader only*/
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.flex {
  display: flex;
}
.hidden {
  overflow: hidden;
}
.btn {
  border: 1px solid var(--dark-blue);
  padding: 0.5rem 0.45rem;
  font-weight: 600;
  border-radius: 6px;
  color: var(--dark-blue);
}
.blueButton {
  border-radius: 1rem;
  background-color: var(--dark-blue);
  color: white;
  padding: 0.5rem 1rem;
  font-family: "FbReforma-Medium";
  letter-spacing: 0.03em;
  min-width: 142px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.arrow {
  display: inline-block;
  transition: all 0.2s;
  transform-origin: center;
  padding: 3px;
  background: transparent;
  border: transparent;
  box-sizing: border-box;
  box-shadow: 0.2vmin 0.2vmin 0 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 3px;
}
.rotateRight {
  transform: rotate(-45deg);
  /* margin-top: -3px; */
}

.rotateLeft {
  transform: rotate(135deg);
}
.rotateUp {
  transform: rotate(-135deg);
}
.rotateDown {
  transform: rotate(45deg);
}

.container {
  padding-right: 60px;
  padding-left: 60px;
  max-width: calc(1280px - 120px);
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0;
  }
}

@media only screen and (max-width: 1200) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.contentCard {
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(205, 205, 205, 1);
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 15%);
}

.pageTitle {
  font-size: 39px;
  color: var(--dark-blue);
  text-align: right;
  letter-spacing: 0.02em;
}

.required_asterisk {
  height: 17px;
  width: 7px;
  font-family: "FbReforma-Medium";

  color: rgba(0, 119, 198, 1);
  text-align: right;
  letter-spacing: 0px;
}
