:root {
  --blue: #0077c6;
  --cyan: #0dcaf0;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #e5190b;

  --orange: #fd7e14;
  --green: #008958;
  --teal: #20c997;
  --white: #fff;
  --dark-gray: #282828;
  --success: #198754;
  --light: #f8f9fa;
  --dark: #050505;
  /* --gray: #868585; */
  --gray: rgba(117, 117, 117, 1);

  --platinum-gray: rgba(226, 226, 226, 1);
  --old-lavender: #757575;

  --dim-gray: #707070;

  --ghost-white: #f9f9f9;
  --pastel-gray: #cdcdcd;
  --platinum: #e2e2e2;
  --dark-cyan: #00acec;
  --dark-blue: #00458a;

  --light-blue: #e9f7fc;
  --dark-blue-half: rgba(0, 69, 138, 0.5);
  --cool-black: rgba(0, 47, 94, 1);

  --white-smoke: #f3f3f3;
  --koromiko: #f9ba4d;

  --bittersweet: #f06f65;
  --nero: #282828;
  --pattens-blue: #d8f2fc;

  --font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  --gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}


@font-face {
  font-family: 'FbReforma';
  src: url(/static/media/FbReforma-Regular.72b5e179.eot);
  src: url(/static/media/FbReforma-Regular.72b5e179.eot#iefix) format('embedded-opentype'),
       url(/static/media/FbReforma-Regular.ba88a1ee.woff) format('woff'),
       url(/static/media/FbReforma-Regular.88db62ba.ttf) format('truetype'),
       url(/static/media/FbReforma-Regular.b2aecdda.svg#FbReforma) format('svg');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39,  U+003A-0040;
}
@font-face {
  font-family: 'FbReforma-Medium';
  src: url(/static/media/FbReforma-Medium.62968891.eot);
  src: url(/static/media/FbReforma-Medium.62968891.eot#iefix) format('embedded-opentype'),
       url(/static/media/FbReforma-Medium.c6a0f401.woff) format('woff'),
       url(/static/media/FbReforma-Medium.be30965d.ttf) format('truetype'),
       url(/static/media/FbReforma-Medium.f2bc9060.svg#FbReforma) format('svg');
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39, U+003A-0040;
}
@font-face {
  font-family: 'Neue Helvetica';
  src: url(/static/media/nhr.affb95de.eot);
  src: url(/static/media/nhr.affb95de.eot#iefix) format('embedded-opentype'),
     url(/static/media/nhr.fd4e37a6.woff2) format('woff2'),
       url(/static/media/nhr.fe9d23d1.woff) format('woff'),
       url(/static/media/nhr.f5829dd7.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Neue Helvetica';
  src: url(/static/media/nhm.ec77709c.eot);
  src: url(/static/media/nhm.ec77709c.eot#iefix) format('embedded-opentype'),
     url(/static/media/nhm.a0f5f0c6.woff2) format('woff2'),
       url(/static/media/nhm.fafb89bc.woff) format('woff'),
       url(/static/media/nhm.006b7e6e.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face { 
  font-family: 'Neue Helvetica for-Hebrew';
  src: url(/static/media/nhr.affb95de.eot);
  src: url(/static/media/nhr.affb95de.eot#iefix) format('embedded-opentype'),
     url(/static/media/nhr.fd4e37a6.woff2) format('woff2'),
       url(/static/media/nhr.fe9d23d1.woff) format('woff'),
       url(/static/media/nhr.f5829dd7.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0040-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
@font-face {
  font-family: 'Neue Helvetica for-Hebrew';
  src: url(/static/media/nhm.ec77709c.eot);
  src: url(/static/media/nhm.ec77709c.eot#iefix) format('embedded-opentype'),
     url(/static/media/nhm.a0f5f0c6.woff2) format('woff2'),
       url(/static/media/nhm.fafb89bc.woff) format('woff'),
       url(/static/media/nhm.006b7e6e.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0040-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
@font-face {
  font-family: "Glyphicons Halflings";
  src: url(/static/media/glyphicons-halflings-regular.5be1347c.eot);
  src: url(/static/media/glyphicons-halflings-regular.5be1347c.eot?#iefix) format("embedded-opentype"),
    url(/static/media/glyphicons-halflings-regular.82b1212e.woff) format("woff"),
    url(/static/media/glyphicons-halflings-regular.4692b9ec.ttf) format("truetype"),
    url(/static/media/glyphicons-halflings-regular.060b2710.svg#glyphicons-halflingsregular)
      format("svg");
}
@font-face {
  font-family: 'rail';
  src: url(/static/media/rail.3a2da16d.eot);
  src: url(/static/media/rail.3a2da16d.eot#iefix) format('embedded-opentype'),
      url(/static/media/rail.8b951cf6.woff2) format('woff2'),
       url(/static/media/rail.048fb834.woff) format('woff'),
       url(/static/media/rail.74ad4c3f.ttf) format('truetype'),
       url(/static/media/rail.567bf523.svg#rail) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
font-family: 'rail-share';
src: url(/static/media/rail-share.b0311a2f.eot);
src: url(/static/media/rail-share.b0311a2f.eot#iefix) format('embedded-opentype'),
     url(/static/media/rail-share.a04eaefd.woff2) format('woff2'),
     url(/static/media/rail-share.e8846abf.woff) format('woff'),
     url(/static/media/rail-share.0a5ed529.ttf) format('truetype'),
     url(/static/media/rail-share.1395000d.svg#rail-share) format('svg');
font-weight: normal;
font-style: normal;
}
.MuiInput-underline:before {
  border-bottom-color: #282828 !important;
}

/*colors*/
.darkBlue {
  color: var(--dark-blue);
}
.darkCyan {
  color: var(--dark-cyan);
}
.gray {
  color: var(--gray);
}
.blue {
  color: var(--blue);
}

.lightBlue {
  color: var(--light-blue);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
  margin-top: 50px;
  margin-bottom: 50px;
}

::-webkit-scrollbar-thumb {
  background: var(--pastel-gray);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--light-gray);
}

/* elements -------------------------------------------------------------------------------------------- */
html,
body {
  direction: rtl;
}
html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-writing-mode: inherit;
      writing-mode: inherit;
  letter-spacing: 0.03em;
}

button,
/* [role="button"], */
[type="button"] {
  border: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  background: none;
  -ms-flex-pack: center;
      justify-content: center;
}
input[type="submit"] {
  cursor: pointer;
}
button:disabled,
[role="button"]:disabled,
[type="button"]:disabled {
  cursor: default;
  opacity: 0.5;
}

button:focus {
  outline: 1px solid black;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-family: "FbReforma-Medium";
  font-size: 39px;
  color: rgba(0, 69, 138, 1);
  text-align: right;
  letter-spacing: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* selectors -------------------------------------------------------------------------------*/
#root {
  /* height: 100%; */
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.customScrollbar {
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}
.customScrollbar::-webkit-scrollbar {
  width: 5px;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--gray);
}

input[type="checkbox"] {
  position: relative;
  width: 17px;
  height: 17px;
  top: 3px;
}

input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  left: 0;
}

input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 0;
  left: 0;
  background-color: rgba(216, 242, 252, 1);
  border: 1px solid rgba(0, 69, 138, 1);
  border-radius: 2px;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid rgba(0, 69, 138, 1);
  border-width: 0 1px 1px 0;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.sr-only {
  /*screen reader only*/
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.flex {
  display: -ms-flexbox;
  display: flex;
}
.hidden {
  overflow: hidden;
}
.btn {
  border: 1px solid var(--dark-blue);
  padding: 0.5rem 0.45rem;
  font-weight: 600;
  border-radius: 6px;
  color: var(--dark-blue);
}
.blueButton {
  border-radius: 1rem;
  background-color: var(--dark-blue);
  color: white;
  padding: 0.5rem 1rem;
  font-family: "FbReforma-Medium";
  letter-spacing: 0.03em;
  min-width: 142px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  box-sizing: border-box;
}
.arrow {
  display: inline-block;
  transition: all 0.2s;
  -ms-transform-origin: center;
      transform-origin: center;
  padding: 3px;
  background: transparent;
  border: transparent;
  box-sizing: border-box;
  box-shadow: 0.2vmin 0.2vmin 0 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 3px;
}
.rotateRight {
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
  /* margin-top: -3px; */
}

.rotateLeft {
  -ms-transform: rotate(135deg);
      transform: rotate(135deg);
}
.rotateUp {
  -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
}
.rotateDown {
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}

.container {
  padding-right: 60px;
  padding-left: 60px;
  max-width: calc(1280px - 120px);
  margin: 0 auto;
}
@media only screen and (max-width: 960px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
    margin: 0;
  }
}

@media only screen and (max-width: 1200) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.contentCard {
  padding: 32px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(205, 205, 205, 1);
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 15%);
}

.pageTitle {
  font-size: 39px;
  color: var(--dark-blue);
  text-align: right;
  letter-spacing: 0.02em;
}

.required_asterisk {
  height: 17px;
  width: 7px;
  font-family: "FbReforma-Medium";

  color: rgba(0, 119, 198, 1);
  text-align: right;
  letter-spacing: 0px;
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.c7cd6f72.eot);
  src:  url(/static/media/icomoon.c7cd6f72.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.143568ba.ttf) format('truetype'),
    url(/static/media/icomoon.5dba7c1c.woff) format('woff'),
    url(/static/media/icomoon.4d2dfd28.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e903";
  color: #00458a;
}
.icon-location:before {
  content: "\e902";
}
.icon-train:before {
  content: "\e901";
}
.icon-crowd:before {
  content: "\e900";
  color: #cdcdcd;
}


.icon-crowd:before {
  content: "\e900";
  color: var(--pastel-gray);
}
.icon-crowd.crowdGreen:before {
  content: "\e900";
  color: #008958;
}
.icon-train:before {
  content: "\e901";
  color:var(--dark);
}
.icon-train.disabled:before{
  content: "\e901";
  color: var(--pastel-gray);
}
.icon-location.disabled:before{
  content: "\e902";
  color: var(--pastel-gray);
}
.icon-search.blue:before {
  content: "\e903";
  color: #00458a;
}
.icon-search.gray:before {
  content: "\e903";
  color: rgba(0,0,0,.4)
}
.Login_MuiFormLabel-root__35sfT.Login_Mui-error__3nSi9 {
  color: blue;
}

.Login_background__3jk9m {
  background-image: url(/static/media/bg_large.dffa61d2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  height: 487px;
  position: fixed;
  width: 100vw;
}

.Login_header__1GD9X {
  color: var(--dark-blue);
  letter-spacing: 0px;
  font-size: 23px;
  text-align: center;
}

.Login_logo__-HTK- {
  height: 20px;
  width: 44px;
}

.Login_wrapper__2aiwR {
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  /* width: 100vw; */
  max-width: 1002px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.Login_registerBox__uRKE7 {
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
}

.Login_resetPassBox__1W6Aj {
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 97px;
  padding-right: 97px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  width: 520px;
}

.Login_registerSuccessBox__1IXAO {
  width: 80vw;
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 16.5%;
  padding-right: 16.5%;
  padding-top: 30px;
  padding-bottom: 123px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.Login_pass_label__2dY3c {
  font-weight: 400;
  color: rgba(117, 117, 117, 1);
  font-size: 12px;
}

.Login_loginBox__1R6cN {
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 97px;
  padding-right: 97px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  width: 520px;
}

.Login_register__field__XD3_k {
  padding: 18px 15px;
  text-align: right;
  position: relative;
  direction: ltr;
}

.Login_register__comment__1i8Y0 {
  color: var(--dark-blue);
  line-height: 22px;
  text-align: center;
  width: 35%;
  display: inline-block;
}

.Login_register__buttonWrapper__Ggrmr {
  margin-top: 27px;
}
.Login_register__alreadyHaveUserName__AA3fv,
.Login_login__activeDirectory__101ju {
  color: var(--blue);
  margin-top: 30px;
  /* text-decoration: underline; */
}

.Login_showPass__1wTa5 {
  position: relative;
}

.Login_showPass__1wTa5 {
  position: absolute;
  right: 0%;
}

.Login_login__activeDirectory__101ju a:hover {
  cursor: pointer;
}

.Login_register__success__17oGZ {
  margin-top: 53px;
  font-size: 30px;
  color: var(--dark-blue);
  font-family: "FbReforma";
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 15px;
}

.Login_resetPassButton__8vND3 {
  height: 40px;
  min-width: 32%;
  font-family: "FbReforma";
  margin-top: 30px !important; /*MUI override*/
}

.Login_register__successComment__2QC6A {
  margin-top: 23px;

  font-family: "FbReforma";
  line-height: 22px;
  font-weight: 400;
  color: var(--nero);
}

.Login_register__successLogo__1ZfmG {
  margin-top: 40px;
}

@media screen and (max-width: 920px) {
  .Login_registerSuccessBox__1IXAO {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 55px;
  }
  .Login_register__success__17oGZ {
    font-size: 21px;
  }
  .Login_register__success__17oGZ {
    margin-top: 35px;
  }
}

.Login_forgotPassword__3hdKw {
  color: var(--blue);
  font-size: 14px;
}

@media screen and (max-width: 920px) {
  .Login_registerBox__uRKE7,
  .Login_registerSuccessBox__1IXAO,
  .Login_loginBox__1R6cN {
    width: 87%;
  }
  .Login_loginBox__1R6cN {
    padding-left: 16px;
    padding-right: 16px;
  }

  .Login_background__3jk9m {
    height: 256px;
    background-position-x: 83%;
  }

  .Login_loginInput__11WQq {
    margin-top: 57px;
    width: 90%;
  }

  .Login_resetPassBox__1W6Aj {
    padding: 20px 20px 20px 10px;
    width: 90%;
  }

  .Login_wrapper__2aiwR {
    min-height: auto;
    padding-top: 83px;
    padding-bottom: 50px;
    width: 100%;
  }

  .Login_register__comment__1i8Y0 {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .Login_background__3jk9m {
    background-image: url(/static/media/background_image_mobile.f891a4e1.png);
    background-size: cover;
  }

  .Login_wrapper__2aiwR {
    padding-top: 133px;
  }
}


.Login_loginCard__2Q66G{
    border:2px solid #ccc;
    border-radius: 4px;
    padding:20px;
    margin-top:8px;
}

.Login_loginBoxTitle__2LGur{
    color: var(--dark-blue);
    letter-spacing: 0px;
    font-size: 18px;
    text-align: center;
    margin:0;
    font-family: "FbReforma-Medium";

    padding-right:calc(100% - 210px);
    padding-left:calc(100% - 210px);
}

.Login_loginBox__1R6cN > div{
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
}
.GlobalLoader_globalLoader__3_H6v{
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100px;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  z-index: 2000;
}

.GlobalLoader_globalLoaderWrapper__1LELH {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 999999;
}
