
@font-face {
  font-family: 'FbReforma';
  src: url('../fonts/FbReforma-Regular.eot');
  src: url('../fonts/FbReforma-Regular.eot#iefix') format('embedded-opentype'),
       url('../fonts/FbReforma-Regular.woff') format('woff'),
       url('../fonts/FbReforma-Regular.ttf') format('truetype'),
       url('../fonts/FbReforma-Regular.svg#FbReforma') format('svg');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39,  U+003A-0040;
}
@font-face {
  font-family: 'FbReforma-Medium';
  src: url('../fonts/FbReforma-Medium.eot');
  src: url('../fonts/FbReforma-Medium.eot#iefix') format('embedded-opentype'),
       url('../fonts/FbReforma-Medium.woff') format('woff'),
       url('../fonts/FbReforma-Medium.ttf') format('truetype'),
       url('../fonts/FbReforma-Medium.svg#FbReforma') format('svg');
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0000-0029, U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F, U+30-39, U+003A-0040;
}
@font-face {
  font-family: 'Neue Helvetica';
  src: url('../fonts/nhr.eot');
  src: url('../fonts/nhr.eot#iefix') format('embedded-opentype'),
     url('../fonts/nhr.woff2') format('woff2'),
       url('../fonts/nhr.woff') format('woff'),
       url('../fonts/nhr.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Neue Helvetica';
  src: url('../fonts/nhm.eot');
  src: url('../fonts/nhm.eot#iefix') format('embedded-opentype'),
     url('../fonts/nhm.woff2') format('woff2'),
       url('../fonts/nhm.woff') format('woff'),
       url('../fonts/nhm.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face { 
  font-family: 'Neue Helvetica for-Hebrew';
  src: url('../fonts/nhr.eot');
  src: url('../fonts/nhr.eot#iefix') format('embedded-opentype'),
     url('../fonts/nhr.woff2') format('woff2'),
       url('../fonts/nhr.woff') format('woff'),
       url('../fonts/nhr.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0040-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
@font-face {
  font-family: 'Neue Helvetica for-Hebrew';
  src: url('../fonts/nhm.eot');
  src: url('../fonts/nhm.eot#iefix') format('embedded-opentype'),
     url('../fonts/nhm.woff2') format('woff2'),
       url('../fonts/nhm.woff') format('woff'),
       url('../fonts/nhm.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0040-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/glyphicons-halflings-regular.woff") format("woff"),
    url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"),
    url("../fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular")
      format("svg");
}
@font-face {
  font-family: 'rail';
  src: url('../fonts/rail.eot?37640046');
  src: url('../fonts/rail.eot?37640046#iefix') format('embedded-opentype'),
      url('../fonts/rail.woff2?37640046') format('woff2'),
       url('../fonts/rail.woff?37640046') format('woff'),
       url('../fonts/rail.ttf?37640046') format('truetype'),
       url('../fonts/rail.svg?37640046#rail') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
font-family: 'rail-share';
src: url('../fonts/rail-share.eot?90541472');
src: url('../fonts/rail-share.eot?90541472#iefix') format('embedded-opentype'),
     url('../fonts/rail-share.woff2?90541472') format('woff2'),
     url('../fonts/rail-share.woff?90541472') format('woff'),
     url('../fonts/rail-share.ttf?90541472') format('truetype'),
     url('../fonts/rail-share.svg?90541472#rail-share') format('svg');
font-weight: normal;
font-style: normal;
}