:root {
  --blue: #0077c6;
  --cyan: #0dcaf0;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #e5190b;

  --orange: #fd7e14;
  --green: #008958;
  --teal: #20c997;
  --white: #fff;
  --dark-gray: #282828;
  --success: #198754;
  --light: #f8f9fa;
  --dark: #050505;
  /* --gray: #868585; */
  --gray: rgba(117, 117, 117, 1);

  --platinum-gray: rgba(226, 226, 226, 1);
  --old-lavender: #757575;

  --dim-gray: #707070;

  --ghost-white: #f9f9f9;
  --pastel-gray: #cdcdcd;
  --platinum: #e2e2e2;
  --dark-cyan: #00acec;
  --dark-blue: #00458a;

  --light-blue: #e9f7fc;
  --dark-blue-half: rgba(0, 69, 138, 0.5);
  --cool-black: rgba(0, 47, 94, 1);

  --white-smoke: #f3f3f3;
  --koromiko: #f9ba4d;

  --bittersweet: #f06f65;
  --nero: #282828;
  --pattens-blue: #d8f2fc;

  --font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  --gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
