.MuiFormLabel-root.Mui-error {
  color: blue;
}

.background {
  background-image: url(../../base//assets/images/bitmaps/bg_large.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  height: 487px;
  position: fixed;
  width: 100vw;
}

.header {
  color: var(--dark-blue);
  letter-spacing: 0px;
  font-size: 23px;
  text-align: center;
}

.logo {
  height: 20px;
  width: 44px;
}

.wrapper {
  display: flex;
  z-index: 999;
  /* width: 100vw; */
  max-width: 1002px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerBox {
  text-align: center;
  align-items: center;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
}

.resetPassBox {
  text-align: center;
  align-items: center;
  padding-left: 97px;
  padding-right: 97px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  width: 520px;
}

.registerSuccessBox {
  width: 80vw;
  text-align: center;
  align-items: center;
  padding-left: 16.5%;
  padding-right: 16.5%;
  padding-top: 30px;
  padding-bottom: 123px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.pass_label {
  font-weight: 400;
  color: rgba(117, 117, 117, 1);
  font-size: 12px;
}

.loginBox {
  text-align: center;
  align-items: center;
  padding-left: 97px;
  padding-right: 97px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  width: 520px;
}

.register__field {
  padding: 18px 15px;
  text-align: right;
  position: relative;
  direction: ltr;
}

.register__comment {
  color: var(--dark-blue);
  line-height: 22px;
  text-align: center;
  width: 35%;
  display: inline-block;
}

.register__buttonWrapper {
  margin-top: 27px;
}
.register__alreadyHaveUserName,
.login__activeDirectory {
  color: var(--blue);
  margin-top: 30px;
  /* text-decoration: underline; */
}

.showPass {
  position: relative;
}

.showPass {
  position: absolute;
  right: 0%;
}

.login__activeDirectory a:hover {
  cursor: pointer;
}

.register__success {
  margin-top: 53px;
  font-size: 30px;
  color: var(--dark-blue);
  font-family: "FbReforma";
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 15px;
}

.resetPassButton {
  height: 40px;
  min-width: 32%;
  font-family: "FbReforma";
  margin-top: 30px !important; /*MUI override*/
}

.register__successComment {
  margin-top: 23px;

  font-family: "FbReforma";
  line-height: 22px;
  font-weight: 400;
  color: var(--nero);
}

.register__successLogo {
  margin-top: 40px;
}

@media screen and (max-width: 920px) {
  .registerSuccessBox {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 55px;
  }
  .register__success {
    font-size: 21px;
  }
  .register__success {
    margin-top: 35px;
  }
}

.forgotPassword {
  color: var(--blue);
  font-size: 14px;
}

@media screen and (max-width: 920px) {
  .registerBox,
  .registerSuccessBox,
  .loginBox {
    width: 87%;
  }
  .loginBox {
    padding-left: 16px;
    padding-right: 16px;
  }

  .background {
    height: 256px;
    background-position-x: 83%;
  }

  .loginInput {
    margin-top: 57px;
    width: 90%;
  }

  .resetPassBox {
    padding: 20px 20px 20px 10px;
    width: 90%;
  }

  .wrapper {
    min-height: auto;
    padding-top: 83px;
    padding-bottom: 50px;
    width: 100%;
  }

  .register__comment {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .background {
    background-image: url(../../base//assets/images/bitmaps/background_image_mobile.png);
    background-size: cover;
  }

  .wrapper {
    padding-top: 133px;
  }
}


.loginCard{
    border:2px solid #ccc;
    border-radius: 4px;
    padding:20px;
    margin-top:8px;
}

.loginBoxTitle{
    color: var(--dark-blue);
    letter-spacing: 0px;
    font-size: 18px;
    text-align: center;
    margin:0;
    font-family: "FbReforma-Medium";

    padding-right:calc(100% - 210px);
    padding-left:calc(100% - 210px);
}

.loginBox > div{
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
}