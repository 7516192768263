
.icon-crowd:before {
  content: "\e900";
  color: var(--pastel-gray);
}
.icon-crowd.crowdGreen:before {
  content: "\e900";
  color: #008958;
}
.icon-train:before {
  content: "\e901";
  color:var(--dark);
}
.icon-train.disabled:before{
  content: "\e901";
  color: var(--pastel-gray);
}
.icon-location.disabled:before{
  content: "\e902";
  color: var(--pastel-gray);
}
.icon-search.blue:before {
  content: "\e903";
  color: #00458a;
}
.icon-search.gray:before {
  content: "\e903";
  color: rgba(0,0,0,.4)
}